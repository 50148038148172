<template>
  <StyleDialog
    v-model="openModal"
    :title="$t('setting.importFromXlHelper')"
    :maxWidth="550"
    :fillHeight="true"
  >
    <template slot="content">
      <v-container class="px-10">
        <ul>
          <li v-for="(item, i) in items" :key="i" :class="item.class">
            {{ item.text }}
          </li>
        </ul>
      </v-container>
    </template>
    <template slot="actions">
      <v-row class="pb-6 ma-0">
        <v-col cols="6" class="center-all">
          <v-btn
            elevation="12"
            class="download-btn"
            @click="downloadXls"
            rounded
          >
            <span class="px-2">
              {{ $t('generics.downloadTemplate') }}
            </span>
            <MyIconImage name="export-cloud" type="svg" maxWidth="23" />
          </v-btn>
        </v-col>
        <v-col cols="6" class="justify-end">
          <v-btn
            elevation="12"
            @click="uploadFileOpen = true"
            class="upload-btn"
            rounded
          >
            <span class="px-2">
              {{ $t('generics.uploadFile') }}
            </span>
            <MyIconImage name="upload" maxWidth="23" />
          </v-btn>
        </v-col>
      </v-row>
      <upload-file
        v-if="uploadFileOpen"
        v-model="uploadFileOpen"
        @handleUpload="importFile"
      />
    </template>
  </StyleDialog>
</template>
<script>
import UploadFile from '@/components/UploadFile.vue'
import swalMixins from '@/mixins/swal'
import Exceljs from 'exceljs'
import Papa from 'papaparse'

import MyIconImage from '../MyIconImage.vue'
import StyleDialog from '../StyleDialog.vue'

export default {
  components: { UploadFile, StyleDialog, MyIconImage },
  name: 'UploadUsers',
  mixins: [swalMixins],
  props: { value: Boolean },
  data() {
    return {
      uploadFileOpen: false,
      xlsFileName: 'תבנית להכנסת שמות שחקנים חויה בקליק 037737970.xlsx',
      users: '',
      xlsKeys: {
        מספר: 'remoteId',
        שם: 'name',
        קבוצה: 'groupName',
      },
      items: [
        {
          class: 'text-color-red',
          text: `שימו לב!- בחלונית זו העלאת שמות מתבצעת רק עבור משחקים בטלפונים
בהפעלה בקליקרים יש לשלוח אלינו את קובץ השמות למייל`,
        },
        { class: '', text: 'הכניסו את השמות בתוך תבנית הקובץ להורדה' },
        {
          class: 'font-weight-bold',
          text: 'יש להכניס את מספרי הפלאפונים ללא הספרה 0 בראש המספר, (במשחק בקליקרים הכניסו רק את השמות ללא מספרים כלל)',
        },
        {
          class: '',
          text: 'אין חובה להכניס שם קבוצה (תוכלו להשתמש באופציה זו בכדי לחלק את המשתפים לקבוצות)',
        },
        { class: '', text: 'שימו לב ששם הקבוצה יהיה זהה אצל כל  המשתתפים' },
        {
          class: '',
          text: 'אין לבצע שום שנוי בתבנית הקובץ ובשורה הראשונה ע”מ שהקובץ יטען בהצלחה',
        },
        {
          class: '',
          text: 'שימו לב שתמיד יהיה ניתן להוסיף או להוריד שמות בממשק המשחק עצמו, וכמו כן להגדיר קבוצות ולהוסיף משתתפים לקבוצה',
        },
        {
          class: '',
          text: 'גם מי ששמו לא הוכנס תמיד יוכל להשתתף במשחק, ובמקום השם יופיע מספר הפלאפון שלו',
        },
        { class: '', text: 'לאחר העלאת הקובץ השמות יוכנסו למשחק מיידית' },
        {
          class: '',
          text: 'הקובץ שנכנס למערכת הוא הקובץ האחרון שאתם מעלים במידה וברצונכם לבצע שינויים ו/או להוסיף שמות יש להעלות את כל הקובץ מחדש',
        },
      ],
    }
  },
  computed: {
    game() {
      return this.$store.getters.game
    },
    openModal: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  methods: {
    downloadXls() {
      window.open(`/${this.xlsFileName}`, '_blank')
    },
    async uploadXls(file) {
      const workbook = new Exceljs.Workbook()
      await workbook.xlsx.load(file)
      let jsonData = {}
      workbook.worksheets.forEach(sheet => {
        let firstRow = sheet.getRow(1)
        if (!firstRow.cellCount) return
        let keys = firstRow.values
        sheet.eachRow((row, rowNumber) => {
          if (rowNumber == 1) return
          let values = row.values
          let obj = {}
          for (let i = 1; i < keys.length; i++) {
            obj[this.xlsKeys[keys[i]]] = values[i]
          }
          jsonData[obj.remoteId] = obj
        })
      })
      this.users = JSON.stringify(jsonData)
    },
    async uploadCsv(file) {
      let fileReader = new FileReader()
      fileReader.readAsText(file)
      fileReader.onload = async event => {
        let data = event.target.result
        const parsedData = Papa.parse(data, {
          header: true, // use the first row as header
          skipEmptyLines: true, // skip empty lines
        })
        let users = {}
        parsedData.data.forEach((row, i) => {
          console.log(row)
          users[i] = row
        })
        this.users = JSON.stringify(users)
      }
    },
    async importFile(file) {
      try {
        const name = file.name.toLowerCase()
        if (name.endsWith('.xls') || name.endsWith('.xlsx')) {
          await this.uploadXls(file)
        } else if (name.endsWith('.csv')) {
          await this.uploadCsv(file)
        }
        this.game['users'] = this.users
        await this.$store.dispatch('game/update', this.game)
        this.timeoutSwal({ icon: 'success', title: '' })
        this.uploadFileOpen = false
        this.openModal = false
      } catch (e) {
        this.timeoutSwal({
          icon: 'error',
          title: this.$t('generics.error'),
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-btn {
  border: 2px solid var(--pink);
}
.download-btn {
  border: 2px solid var(--green);
}
.text-color-red {
  color: red;
}
</style>
